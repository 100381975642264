<template>
  <!-- Allocating Offer to User for TASTING-->
  <div>
    <v-overlay :value="overlay"><v-progress-circular :size="70" :width="7" color="green"
        indeterminate></v-progress-circular></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-card class="card-shadow border-radius-xl">
        <div class="ma-5">
          <v-form ref="frmFilter">
            <!-- Style Fix -->
            <v-row>
              <v-col></v-col>
            </v-row>
            <v-row class="mt-8">
              <v-col cols="12" sm="4">
                <label class="text-md font-weight-bolder ms-1 mandatory"><span class="red--text"><strong>*
                    </strong></span>Vendor</label>
                <v-autocomplete v-model="vendor" :items="vendors" item-text="name" item-value="id" return-object
                  color="rgba(0,0,0,.6)" class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mt-5
                      mb-0
                    " placeholder="Select a Vendor" :rules="[(v) => !!v || 'Vendor is Required']" outlined single-line
                  height="48" @change="getSeason">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <label class="text-md font-weight-bolder ms-1 mandatory"><span class="red--text"><strong>*
                    </strong></span>Season</label>
                <v-autocomplete return-object v-model="season" :items="seasons" item-text="name" item-value="id"
                  color="rgba(0,0,0,.6)" class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mt-5
                      mb-0
                    " placeholder="Select a Season" :rules="[(v) => !!v || 'Season is Required']" outlined single-line
                  height="48" @change="getOffers">
                </v-autocomplete> </v-col><v-col cols="12" sm="4">
                <label class="text-md font-weight-bolder ms-1 mandatory"><span class="red--text"><strong>*
                    </strong></span>Offer
                  No</label>
                <v-autocomplete return-object v-model="offer" :items="offers" item-text="offer_no" color="rgba(0,0,0,.6)"
                  class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mt-5
                      mb-0
                    " placeholder="Select a Vendor" :rules="[(v) => !!v || 'Offer is Required']" outlined single-line
                  height="48">
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" sm="4"><label class="text-md font-weight-bolder ms-1 mandatory"><span
                    class="red--text"><strong>* </strong></span>Tea
                  Type</label>
                <v-autocomplete multiple v-model="tea_type" :items="tea_types" item-text="tea_type_name" item-value="id"
                  color="rgba(0,0,0,.6)" class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mt-5
                      mb-0
                    " placeholder="Select a Tea Type" outlined single-line height="48"
                  :rules="[(v) => !!v || 'Tea Type is Required']"><template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                      <span class="text-white text-caption ls-0">{{
                        item.tea_type_name
                      }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ tea_type.length - 1 }}
                      Types)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
          <v-row dense>
            <v-col class="d-flex justify-end mx-5 mb-5">
              <v-btn @click="close" elevation="0" :ripple="false" height="43" class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-secondary
                    bg-light
                    py-3
                    px-6
                    mx-2
                  ">Clear</v-btn>

              <v-btn @click="searchData" elevation="0" :ripple="false" height="43" dark class="
                    font-weight-bold
                    text-capitalize
                    btn-primary
                    bg-success
                    py-3
                    px-6
                  ">Search</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
      <!-- DATA TABLE -->
      <v-card class="card-shadow border-radius-xl mt-5" v-if="result.length > 0">
        <div class="card-header-padding">
          <div class="d-flex align-center">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Total sold
              </h5>
            </div>
          </div>
        </div>
        <v-card-text class="card-padding">
          <v-data-table :headers="headers" :items="result" fixed-header hide-default-footer class="table"
            disable-pagination>
            <template v-slot:top>
              <v-toolbar flat>
                <!-- <v-toolbar-title>Purchase history</v-toolbar-title> -->

                <v-spacer></v-spacer>
                <v-btn @click="excelExport" elevation="0" :ripple="false" height="43" dark class="
                      font-weight-bold
                      text-capitalize
                      btn-primary
                      bg-success
                      py-5
                      px-5
                      shadow
                    ">
                  <v-icon size="20" class="text-white mr-1">
                    fas fa-cloud-download-alt
                  </v-icon>
                  Download
                </v-btn>
              </v-toolbar>
            </template>
            <template slot="body.append">
              <tr class="bolder">
                <th class="title font-weight-bolder">Grand Total</th>

                <th class="title font-weight-bolder">
                  {{ sumField(result, "total_sold") }}
                </th>
                <th class="title font-weight-bolder">
                  {{ sumField(result, "total_value") }}
                </th>
                <th class="title font-weight-bolder">
                  {{
                    parseFloat(
                      sumField(result, "total_value") /
                      sumField(result, "total_sold")
                    ).toFixed(3)
                  }}
                </th>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import api from "../api";
import apiMaster from "../../../master/master-get-api";
export default {
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialog: false,
      overlay: false,
      showTable: false,
      list_name: "",
      vendors: [],
      vendor: null,
      seasons: [],
      season: null,
      offers: [],
      offer: null,
      tea_types: [],
      tea_type: [],
      result: [],
      headers: [
        {
          text: "Item",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: false,
        },
        {
          text: "total sold",
          value: "total_sold",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: false,
        },
        {
          text: "total value",
          value: "total_value",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: false,
        },
        {
          text: "average price",
          value: "average_price",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: false,
        },
      ],
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.overlay = true;

        this.vendors = await apiMaster.getPrivateVendors();
        this.tea_types = await apiMaster.getTeaType();
      } catch (error) {
      } finally {
        this.overlay = false;
      }
    },
    async getSeason() {
      try {
        this.seasons = [];
        this.season = null;
        this.overlay = true;
        this.offers = [];
        this.offer = null;
        this.seasons = await apiMaster.getSeasonByVendor(this.vendor.id);
      } catch (error) {
      } finally {
        this.overlay = false;
      }
    },

    async getOffers() {
      try {
        this.offers = [];
        this.offer = null;
        this.overlay = true;
        this.offers = await apiMaster.getOffersByvendorSeason(
          this.vendor.id,
          this.season.id
        );
      } catch (error) {
      } finally {
        this.overlay = false;
      }
    },

    searchValidation() {
      return this.$refs.frmFilter.validate();
    },
    saveValidation() {
      return this.$refs.frmSave.validate();
    },
    async searchData() {
      this.result = [];
      if (this.searchValidation()) {
        try {
          this.overlay = true;
          let req = this.getReq();
          this.result = await api.getReport(req);
          this.overlay = false;
          if (this.result.length == 0) {
            this.noDataAlert("No Data Found...");
          }
          console.log("r", this.result);
        } catch (error) {
          this.showErrorAlert(error);
        }
      }
    },
    async excelExport() {
      try {
        this.overlay = true;
        let req = await this.getReq();
        await api.purchaseSummeryExcelImport(req);

        this.overlay = false;
      } catch (error) {
        this.showErrorAlert(error);
      }
    },

    toggle() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.grade = [];
        } else {
          this.grade = this.grades.slice();
        }
      });
    },
    clear() {
      this.$refs.frmSave.reset();
      this.selected = [];
      this.role = null;
      this.list_name = "";
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        showConfirmButton: false,
        type: "success",
        timer: 3000,
        icon: "success",
      });
    },
    noDataAlert(message) {
      this.$swal({
        text: message,

        timer: 3000,

        showConfirmButton: false,
      });
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        showConfirmButton: true,
        type: "error",

        icon: "error",
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    getReq() {
      let req = {
        offer_no: this.offer.id,
        tea_type_id: this.tea_type,

        // item_name: item_names,
      };
      return req;
    },
    sumField(array, key) {
      // sum data in give key (property)
      return array.reduce((a, b) => parseInt(a) + parseInt(b[key] || 0), 0);
    },
    noDataFoundAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    close() {
      this.vendor = null;
      this.season = null;
      this.offer_no = null;
      this.tea_type = null;
      this.grade = [];
      this.seasons = [];
      this.offers = [];
      this.$refs.frmFilter.resetValidation();
    },
  },

  computed: {
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
    selectAll() {
      return this.grade.length === this.grades.length;
    },
    selectSome() {
      return this.grade.length > 0 && !this.selectAll;
    },
    icon() {
      if (this.selectAll) return "ni-fat-remove text-lg";
      if (this.selectSome) return "ni-fat-delete";
      return "fa-square-o";
    },
  },
};
</script>

<style></style>
